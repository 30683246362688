export const defaultAthlete = {
  lastname: "",
  firstname: "",
  patronymic: "",
  birthDate: null,
  region_ru: { id: null },
  category_sport: { id: null },
  category_people: null,
  rank: { id: null },
  photo: null,
  published_at: null,
  hobby: "",
  instagram: "",
  gender: "",
};

export const formSkippedFields = ["photo", "images", "id", "_id", "updated_at"];

export const AGE_MIN_YEAR = 40;
export const AGE_MAX_YEAR = 10;

export const athletesLoadRequiredColumns = [
  {
    field: "sport",
    label: "Вид спорта",
  },
  {
    field: "gender",
    label: "Пол",
  },
  {
    field: "lastname",
    label: "Фамилия",
  },
  {
    field: "firstname",
    label: "Имя",
  },
  {
    field: "patronymic",
    label: "Отчество",
  },
  {
    field: "birthDay",
    label: "Дата рождения",
  },
  {
    field: "rank",
    label: "Звание",
  },
  {
    field: "region",
    label: "Регион (основной)",
  },

  {
    field: "region2",
    label: "Регион (параллельный)",
  },
];

export const athletesLoadRequiredData = [
  {
    sport: "Лыжные гонки",
    gender: "Женщины",
    lastname: "Иванов",
    firstname: "Иван",
    patronymic: "Иванович",
    birthDay: "2006-10-15",
    rank: "MC",
    region: "город Москва",
    region2: "Ивановская область",
  },
  {
    sport: "Конькобежный спорт",
    gender: "Мужчины",
    lastname: "Петрова",
    firstname: "Мария",
    patronymic: "Павловна",
    birthDay: "2002-01-05",
    rank: "MCМК",
    region: "Ивановская область",
    region2: "",
  },
];

// Шаблон спортсменов для загрузки, лежит в public
export const ATHLETES_TEMPLATE = "/download/athletes-template.xlsx";
