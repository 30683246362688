<template>
  <div class="buttons mt-2">
    <b-button
      type="is-success"
      icon-left="check"
      :loading="isLoading"
      :disabled="isSubmitDisabled"
      @click="$emit('send')"
    >
      {{ saveTitle }}
    </b-button>

    <b-button
      v-if="isShowReset"
      type="is-warning"
      icon-left="autorenew"
      :disabled="isResetDisabled"
      @click="$emit('reset')"
    >
      Очистить
    </b-button>

    <b-button v-if="isShowClose" icon-left="close" @click="$emit('close')">
      Закрыть
    </b-button>
  </div>
</template>

<script>
export default {
  name: "AppFormButtons",
  props: {
    isShowReset: {
      type: Boolean,
      default: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      default: false,
    },
    isResetDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isShowClose: {
      type: Boolean,
      default: true,
    },
    saveTitle: {
      type: String,
      default: "Сохранить",
    },
  },
};
</script>
