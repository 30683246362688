<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ athlete.id ? "Редактирование спортсмена" : "Новый спортсмен" }}
        </p>
      </header>

      <b-loading
        :is-full-page="false"
        v-model="isDataLoading"
        :can-cancel="false"
      ></b-loading>

      <section class="modal-card-body">
        <div class="columns is-vcentered">
          <div class="column">
            <ValidationProvider
              v-slot="{ errors, valid }"
              rules="required|min:2"
              name="Фамилия"
              slim
            >
              <b-field
                label="Фамилия"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input v-model="edited.lastname" type="text" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, valid }"
              rules="required|min:2"
              name="Имя"
              slim
            >
              <b-field
                label="Имя"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input v-model="edited.firstname" type="text" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, valid }"
              rules="required|min:2"
              name="Отчество"
              slim
            >
              <b-field
                label="Отчество"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input v-model="edited.patronymic" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>

        <div class="columns">
          <ValidationProvider
            v-slot="{ errors, valid }"
            rules="required"
            name="Дата рождения"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="Дата рождения"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-datepicker
                v-model="birthDate"
                placeholder="Выберите дату рождения"
                icon="calendar-today"
                locale="ru-Ru"
              >
                >
              </b-datepicker>
            </b-field>
          </ValidationProvider>

          <div class="column is-half-desktop">
            <AppFormFieldGender
              :slim="false"
              :gender="athlete.gender"
              @setGender="setGender"
            />
          </div>
        </div>

        <div class="columns">
          <ValidationProvider
            v-slot="{ errors, valid }"
            rules="required"
            name="Вид спорта"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="Вид спорта"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-select
                v-model="edited.category_sport.id"
                placeholder="Выберите вид спорта"
              >
                <option
                  v-for="sport in sports"
                  :key="sport.id"
                  :value="sport.id"
                >
                  {{ sport.Title }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, valid }"
            rules="required"
            name="Звание"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="Звание"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-select v-model="edited.rank.id" placeholder="Выберите звание">
                <option v-for="rank in ranks" :key="rank.id" :value="rank.id">
                  {{ rank.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="columns">
          <ValidationProvider
            v-slot="{ errors, valid }"
            rules="required"
            name="Регион основной"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="Регион основной"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-select
                v-model="edited.region_ru.id"
                placeholder="Выберите регион"
              >
                <option
                  v-for="region in regions"
                  :key="region.id"
                  :value="region.id"
                >
                  {{ region.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, valid }"
            rules=""
            name="Регион параллельный"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="Регион параллельный"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-select
                v-model="edited.region_ru_2.id"
                placeholder="Выберите регион"
              >
                <option :value="null">Нет</option>
                <option
                  v-for="region in regions"
                  :key="region.id"
                  :value="region.id"
                >
                  {{ region.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="columns">
          <ValidationProvider
            v-slot="{ errors, valid }"
            rules=""
            name="Хобби"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="Хобби"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors[0]"
            >
              <b-input v-model="edited.hobby" type="text" />
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, valid }"
            rules=""
            name="instagram"
            tag="div"
            class="column is-half-desktop"
          >
            <b-field
              label="instagram"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors[0]"
            >
              <b-input v-model="edited.instagram" type="text" />
            </b-field>
          </ValidationProvider>
        </div>

        <ValidationProvider
          rules="min:10"
          name="Регалии"
          tag="div"
          class="block mt-3"
          v-slot="{ errors, valid }"
        >
          <b-field
            label="Регалии"
            :message="errors[0]"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <v-md-editor
              v-model="edited.description"
              height="400px"
            ></v-md-editor>
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="isLoading"
          :is-show-reset="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @close="$emit('close')"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { DATE_FORMAT, Resource } from "@/common/const/common";
import { getFormData } from "../utils";
import {
  ATHLETE_ADDED,
  NETWORK_ERROR,
  SAVE_ERROR,
  SAVE_SUCCESS,
} from "@/common/const/message";
import { cloneDeep } from "lodash";
import deleteTimestamps from "@/common/mixins/delete-timestamps";
import AppFormButtons from "@/common/components/AppFormButtons";
import { getImgUrl } from "@/common/utils/common";
import AppFormFieldGender from "@/common/components/AppFormFieldGender";

export default {
  name: "AthleteForm",

  components: {
    AppFormFieldGender,
    AppFormButtons,
    ValidationObserver,
    ValidationProvider,
  },

  mixins: [deleteTimestamps],

  props: {
    athlete: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      edited: {},
      birthDate: null,

      isLoading: false,
      isDataLoading: false,
      gender: null,
      ranks: [],
      sports: [],
      categoryPeople: null,
      regions: [],
    };
  },

  created() {
    this.edited = cloneDeep(this.athlete);
    this.$deleteTimestamps(this.edited);

    if (!this.edited.rank) {
      this.edited.rank = { id: null };
    }

    if (!this.edited.region_ru) {
      this.edited.region_ru = { id: null };
    }

    if (!this.edited.region_ru_2) {
      this.edited.region_ru_2 = { id: null };
    }

    if (this.edited.birthDate) {
      this.birthDate = new Date(this.edited.birthDate);
    }
    this.isPublished = !!this.athlete.published_at;
  },

  async mounted() {
    try {
      this.isDataLoading = true;

      [this.sports, this.ranks, this.categoryPeople, this.regions] =
        await Promise.all([
          this.$api[Resource.SPORTS].get(`?_sort=Title:asc`),
          this.$api.fetchData.get(`${Resource.RANKS}?_sort=name:asc`),
          this.$api.fetchData.get(Resource.CATEGORY_PEOPLE),
          this.$api.fetchData.get(`${Resource.REGIONS}?_sort=name:asc`),
        ]);
    } catch (e) {
      this.$notifier.error(NETWORK_ERROR);
    } finally {
      this.isDataLoading = false;
    }
  },

  async beforeDestroy() {
    this.edited = null;
  },

  methods: {
    setGender(gender) {
      this.edited.gender = gender;
    },
    async handleSubmit() {
      try {
        this.isLoading = true;

        let message;
        let athlete;

        const edited = { ...this.edited };

        edited.birthDate = this.$dayjs(this.birthDate).format(DATE_FORMAT);
        if (!edited.category_people) {
          edited.category_people = this.categoryPeople.find(
            (item) => item.title === "Спортсмен"
          )?.id;
        }
        edited.category_sport = this.edited.category_sport.id;
        edited.rank = this.edited.rank.id;
        edited.region_ru = this.edited.region_ru.id;
        edited.region_ru_2 = this.edited.region_ru_2.id || null;

        if (this.edited.id) {
          athlete = await this.$api[Resource.PEOPLES].put(
            edited,
            this.edited.id
          );
          message = SAVE_SUCCESS;
        } else {
          athlete = await this.$api[Resource.PEOPLES].post(edited);
          message = ATHLETE_ADDED;
        }

        this.$notifier.success(message);
        this.$emit("close", athlete);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
