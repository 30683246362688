import { render, staticRenderFns } from "./AthletesIndex.vue?vue&type=template&id=39629670&"
import script from "./AthletesIndex.vue?vue&type=script&lang=js&"
export * from "./AthletesIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports