<template>
  <div v-if="isEditor" class="buttons">
    <b-tooltip v-if="isShowTooltip" :label="title">
      <b-button
        v-if="isIcon"
        type="is-success"
        icon-right="plus-thick"
        @click="$emit('click')"
      />
      <b-button v-else type="is-success" @click="$emit('click')">
        {{ title }}
      </b-button>
    </b-tooltip>

    <template v-else>
      <b-button
        v-if="isIcon"
        type="is-success"
        icon-right="plus-thick"
        @click="$emit('click')"
      />
      <b-button v-else type="is-success" @click="$emit('click')">
        {{ title }}
      </b-button>
    </template>

    <b-tooltip v-if="isShowLoad" label="Перейти на страницу загрузки из файла">
      <b-button type="is-success is-light" @click="$emit('load')" class="ml-2">
        Загрузить
      </b-button>
    </b-tooltip>

    <b-tooltip v-if="isShowDownload" label="Скачать список">
      <b-button
        type="is-info"
        icon-left="download"
        @click="$emit('download')"
        class="ml-2"
      >
        Скачать
      </b-button>
    </b-tooltip>
  </div>

  <div v-else-if="isShowDownload" class="buttons">
    <b-tooltip label="Скачать список">
      <b-button
        type="is-info"
        icon-left="download"
        @click="$emit('download')"
        class="ml-2"
      >
        Скачать
      </b-button>
    </b-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AddButton",
  props: {
    title: {
      type: String,
      default: "Добавить",
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    isShowTooltip: {
      type: Boolean,
      default: true,
    },
    isShowLoad: {
      type: Boolean,
      default: false,
    },
    isShowDownload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },
};
</script>
