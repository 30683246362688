import { AGE_MAX_YEAR, AGE_MIN_YEAR, formSkippedFields } from "./const";

export const getAgeDiffDates = () => {
  const year = new Date().getFullYear();
  const max = year - AGE_MAX_YEAR;
  const years = [];
  for (let i = year - AGE_MIN_YEAR; i <= max; i++) {
    years.push(i);
  }
  return years.reverse();
};

export const getQueryFilters = async (filters) => {
  const queryFilters = [];
  Object.keys(filters).forEach((key) => {
    if (key === "birthDate" && typeof filters[key] === "number") {
      const startYear = `${filters[key]}-01-01`;
      const endYear = `${filters[key] + 1}-01-01`;
      queryFilters.push(`${key}_gte=${startYear}`);
      queryFilters.push(`${key}_lt=${endYear}`);
    } else if (filters[key].length >= 3) {
      queryFilters.push(`${key}_contains=${filters[key]}`);
    }
  });

  return queryFilters;
};

export const getFormData = (athlete, file) => {
  const formData = new FormData();
  const data = {};

  Object.keys(athlete).forEach((key) => {
    if (!formSkippedFields.includes(key)) {
      data[key] = athlete[key];
    }
  });

  if (file && file instanceof Blob && file !== athlete.photo) {
    formData.append("files.photo", file, file.name);
  }
  formData.append("data", JSON.stringify(data));

  return formData;
};
