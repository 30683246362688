<template>
  <div>
    <h1 class="title">Спортсмены</h1>

    <AddButton
      @click="handleAdd"
      :is-show-load="isEditor"
      @load="handleClickLoad"
    />

    <section>
      <b-table
        :data="data"
        :loading="loading"
        :mobile-cards="false"
        :paginated="isPaginated"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        backend-pagination
        backend-filtering
        :total="total"
        :per-page="perPage"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        :debounce-search="500"
        @page-change="onPageChange"
        @sort="onSort"
        @filters-change="onFiltersChange"
      >
        <b-table-column v-slot="props" label="№">
          {{ props.index + 1 + perPage * (page - 1) }}
        </b-table-column>

        <b-table-column v-slot="props" field="images" label="Фото" sortable>
          <img
            v-if="props.row.photo"
            :src="getImageSrc(props.row)"
            width="50"
            style="border-radius: 0.1rem"
            alt="фото"
          />
        </b-table-column>

        <b-table-column
          field="fullName"
          label="Фамилия, имя, отчество"
          sortable
          searchable
        >
          <template>
            <b-input placeholder="Поиск..." icon="magnify" size="is-small" />
          </template>
          <template v-slot="props">
            <router-link
              :to="{ name: 'AthleteShow', params: { id: props.row.id } }"
            >
              {{ props.row.fullName }}
            </router-link>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="gender"
          label="Пол"
          sortable
          searchable
        >
          {{ props.row.gender }}
        </b-table-column>

        <b-table-column
          field="birthDate"
          label="Дата рождения"
          sortable
          searchable
        >
          <template #searchable="props">
            <b-select
              v-model="props.filters[props.column.field]"
              placeholder="Выберите год"
            >
              <option value="">Все даты</option>
              <option v-for="year in diffDates" :key="year" :value="year">
                {{ year }}
              </option>
            </b-select>
          </template>
          <template v-slot="props">
            {{ props.row.birthDate | date }}
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="rank.code"
          label="Звание"
          sortable
          searchable
        >
          {{ props.row.rank ? props.row.rank.code : "" }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="region_ru.name"
          label="Регион"
          sortable
          searchable
        >
          {{ props.row.region_ru ? props.row.region_ru.name : "" }}
          <template v-if="props.row.region_ru_2">
            <!--            <br />-->
            <span class="has-text-grey is-size-7">
              ({{ props.row.region_ru_2.name }})
            </span>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="category_sport.Title"
          label="Вид спорта"
          sortable
          searchable
        >
          {{ props.row.category_sport.Title }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="published_at"
          label="Статус"
          sortable
          centered
        >
          <b-icon
            v-if="props.row.published_at"
            icon="lock-open-variant"
            size="is-small"
            type="is-success"
          />
          <b-icon v-else icon="lock" size="is-small" type="is-warning" />
        </b-table-column>

        <!--        <b-table-column label="Overview" width="500" v-slot="props">-->
        <!--          {{ props.row.overview | truncate(80) }}-->
        <!--        </b-table-column>-->
      </b-table>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Resource } from "@/common/const/common";
import AddButton from "@/common/components/AddButton";
import AthleteForm from "./AthleteForm";

import { getAgeDiffDates, getQueryFilters } from "../utils";
import { defaultAthlete } from "../const";
import { getImgUrl } from "@/common/utils/common";

export default {
  name: "AthletesIndex",
  components: { AddButton },
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",

      data: [],
      total: 0,
      loading: false,
      sortField: "fullName",
      sortOrder: "asc",
      defaultSortOrder: "asc",
      page: 1,
      perPage: 50,

      diffDates: {},
      filters: [],
    };
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
      isReader: "auth/isReader",
      user: "auth/getUser",
    }),
  },
  async mounted() {
    await this.loadAsyncData();
    this.diffDates = getAgeDiffDates();
    document.title = "Спортсмены";
  },
  methods: {
    getImageSrc({ photo }) {
      if (!photo) {
        return null;
      }
      const url =
        photo.formats?.thumbnail?.url ||
        photo.formats?.small.url ||
        photo.url ||
        null;

      return url ? getImgUrl({ url }) : null;
    },

    async handleClickLoad() {
      await this.$router.push({ name: "AthletesLoad" });
    },

    async handleAdd() {
      this.$buefy.modal.open({
        parent: this,
        component: AthleteForm,
        props: { athlete: defaultAthlete },
        events: { close: this.handleClose },
        hasModalCard: true,
      });
    },

    async handleClose(data) {
      if (data?.id) {
        await this.$router.push({
          name: "AthleteShow",
          params: { id: data.id },
        });
      }
    },
    async onPageChange(page) {
      this.page = page;
      await this.loadAsyncData();
    },
    async onFiltersChange(filters) {
      this.filters = await getQueryFilters(filters);
      await this.loadAsyncData();
    },
    async onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      await this.loadAsyncData();
    },
    async loadAsyncData() {
      const start = (this.page - 1) * this.perPage;
      const params = [
        `find-to-index?_sort=${this.sortField}:${this.sortOrder}`,
        `_limit=${this.perPage}`,
        `_start=${start}`,
        ...this.filters,
      ].join("&");

      let query = this.isReader
        ? [`count?category_sport_null=false`, ...this.filters]
        : [
            `count?category_sport.category_sport_type=${this.user.sportType}`,
            ...this.filters,
          ];

      // const countQuery = [
      //   `count?category_sport.category_sport_type=${this.user.sportType}`,
      //   ...this.filters,
      // ].join("&");

      const countQuery = query.join("&");

      this.loading = true;

      try {
        this.data = await this.$api[Resource.PEOPLES].get(params);
        this.loading = false;
        this.total = await this.$api[Resource.PEOPLES].get(countQuery);
      } catch (e) {
        this.data = [];
        this.total = 0;
        this.loading = false;
      }
    },
  },
};
</script>
