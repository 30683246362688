<template>
  <ValidationProvider rules="required" name="Пол" :slim="slim">
    <b-field
      slot-scope="{ errors, valid }"
      label="Пол"
      :message="errors"
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
    >
      <b-select
        v-model="selectedGender"
        placeholder="Выберите пол"
        @input="(value) => $emit('setGender', value)"
      >
        <option value="">Нет</option>
        <option
          v-for="genderItem in genders"
          :key="genderItem"
          :value="genderItem"
        >
          {{ genderItem }}
        </option>
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { GENDERS } from "@/common/const/common";
import { ValidationProvider } from "vee-validate";

export default {
  name: "AppFormFieldGender",
  components: { ValidationProvider },
  data() {
    return {
      selectedGender: null,
      rules: "",
    };
  },
  props: {
    genders: {
      type: Array,
      default: () => GENDERS,
    },
    gender: {
      type: String,
      required: true,
    },
    slim: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.selectedGender = this.gender;
  },
};
</script>
